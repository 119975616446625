@import "~react-image-crop/src/ReactCrop.scss";

.webcam-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.hidden {
        display: none;
    }
}