@import "~react-tooltip/dist/react-tooltip.css";
@import "~react-dropzone-uploader/dist/styles.css";

.app {
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-secondary-bg-color);
}

.app-header {
    background-color: var(--tg-theme-bg-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

input {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 2px 8px;
    font-size: 1rem;
}

button {
    border-radius: 4px;
    border-width: 0;
    min-height: 38px;
    padding: 2px 8px;
    font-size: 1rem;
}

.help-icon {
    height: 1.2rem;
    width: 1.2rem;
}

.auth-error {
    font-size: 1rem;
    padding: 1rem;
}

table.form {
    width: 100%;
    margin: 0.5em 0;
    border-collapse: separate;

    th {
        font-weight: 500;
        white-space: normal;
        text-align: left;
        padding: .2rem 0 .2rem .4rem;

        &:after {
            content: ':';
        }
    }

    td {
        padding: .2rem 0 .2rem .4rem;
        width: 100%;

        > input[type="text"] {
            width: calc(100% - 1rem);
            background-color: var(--tg-theme-bg-color);
            color: var(--tg-theme-text-color);
            border-color: var(--tg-theme-hint-color);
        }
    }

    th:first-child,
    td:first-child {
        padding-left: 0;
    }

    tr.separator td {
        padding: 0;

        &:empty {
            height: 1em;
        }
    }
}

.select-container__control {
    background-color: var(--tg-theme-bg-color) !important;
}

.select-container__multi-value {
    background-color: var(--tg-theme-button-color) !important;
}

.select-container__single-value {
    color: var(--tg-theme-text-color) !important;
}

.select-container__menu {
    z-index: 10 !important;
    background-color: var(--tg-theme-button-text-color) !important;
}

.select-container__menu-list {
    background-color: var(--tg-theme-bg-color) !important;
}

.select-container__option {
    color: var(--tg-theme-hint-color) !important;
    background-color: var(--tg-theme-bg-color) !important;
}

.select-container__option--is-selected {
    color: var(--tg-theme-button-text-color) !important;
    background-color: var(--tg-theme-button-color) !important;
}

.select-container--is-disabled {
    .select-container__control,
    .select-container__multi-value,
    .select-container__single-value,
    .select-container__menu,
    .select-container__menu-list,
    .select-container__option,
    .select-container__option--is-selected {
        color: var(--tg-theme-hint-color) !important;
        background-color: var(--tg-theme-secondary-bg-color) !important;
    }
}

button {
    color: var(--tg-theme-button-text-color) !important;
    background-color: var(--tg-theme-button-color) !important;
}

.text-right {
    text-align: right;
}

.small {
    font-size: .75em;
}

.dflex {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;

    > div {
        width: 100%;
    }
}

.red {
    color: #dd0000;
}

.vtop {
    vertical-align: top;
}

.tooltip {
    max-width: 200px;
}