.add-book-dialog {
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);

    a {
        color: var(--tg-theme-link-color);
    }

    .add-container {
        background-color: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        transition: max-height 1s, opacity 1s;
        overflow: auto;
        padding: 0 1rem;
        height: calc(var(--tg-viewport-height) - 1rem);
        max-height: calc(var(--tg-viewport-height) - 1rem);

        &.hidden {
            max-height: 0 !important;
            opacity: 0 !important;
        }
    }

    .photo-container,
    .crop-container,
    .fail-container {
        background-color: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        overflow: hidden;
        display: none;
        transition: opacity 1s;
        opacity: 0;
        z-index: 9;
        height: var(--tg-viewport-height);
        max-height: var(--tg-viewport-height);

        &.visible {
            opacity: 1;
            display: block;
        }
    }

    .leasing-block {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: .5rem;
            justify-content: space-between;

            > div {
                display: flex;
                align-items: center;
                column-gap: .5rem;
            }
        }

        &::after {
            content: "" !important;
        }

        .select-container__control {
            width: 80px;
        }
    }

    .photo-block {
        vertical-align: top;
        width: 30%;

        &::after {
            content: "" !important;
        }

        div {
            font-weight: 400;
            padding-top: 1rem;
        }
    }

    .file-upload-container {
        display: flex;
        flex-direction: column;

        button {
            width: 100%;
        }
    }

    .file-preview-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: .5rem;

        button {
            width: 100%;
        }

        .image-preview {
            width: 200px;
        }
    }
}