@import "~react-image-crop/src/ReactCrop.scss";

.crop-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ReactCrop .ord-nw {
    margin-top: -6px;
    margin-left: -6px;
}

.ReactCrop__drag-handle::after {
    width: 12px;
    height: 12px;
    background-color: rgb(255 0 0 / 60%);
}