.select-group-dialog {
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);

    a {
        color: var(--tg-theme-link-color);
    }

    .select-group-container {
        transition: max-height 1s, opacity 1s;
        background-color: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        overflow: hidden;
        padding: 0 1rem;
        height: var(--tg-viewport-height);
        max-height: var(--tg-viewport-height);

        &.hidden {
            max-height: 0 !important;
            opacity: 0 !important;
        }
    }

    .fail-container {
        background-color: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        overflow: hidden;
        display: none;
        transition: opacity 1s;
        opacity: 0;
        padding: 0 1rem;

        &.visible {
            opacity: 1;
            display: block;
        }
    }

    .country-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: .5rem;
    }

    .leasing-block {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: .5rem;
        }

        &::after {
            content: "" !important;
        }

        .select-container__control {
            width: 70px;
        }
    }
}